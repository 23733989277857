import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";

const UserAgreement = () => {
  return (
    <div className="w-full h-[auto] bg-[#030020] pt-[70px] lg:pt-[120px]">
      <Navbar />
      <div className="flex flex-col w-[90vw] mx-auto gap-8">
        <div className="flex flex-row w-[90vw] text-white justify-center px-6 items-center gap-4 my-8">
          <p className="text-[1.2rem] lg:text-4xl font-[600]">
            <div className="flex gap-4 items-center">
              <h2 className="text-white font-[500]">
                End User License Agreement (EULA)
              </h2>
            </div>
          </p>
        </div>

        <p className="mb-4 text-grey otherSection">
          This End User License Agreement ("Agreement") is a legal agreement
          between you ("User", "You") and Prowiz Analytics ("Prowiz Analytics",
          "we", "us", or "our"), the provider of the Sightshark product
          ("Sightshark", "Product"). By accessing, downloading, installing, or
          using the Product (which includes the website, mobile application,
          dashboard, API, or other interfaces through which the Product is
          delivered, collectively the "Product Interface"), you agree to be
          bound by the terms of this Agreement. If you do not agree to the terms
          of this Agreement, do not use the Product.
        </p>

        <h2 className="text-2xl text-white font-semibold mt-6 mb-2">
          1. License Grant
        </h2>
        <p className="mb-2 text-grey otherSection">
          1.1 Grant of License: Subject to your compliance with this Agreement,
          Prowiz Analytics grants you a limited, non-exclusive,
          non-transferable, and revocable license to access and use Sightshark
          for your personal or business use in accordance with this Agreement.
        </p>
        <p className="mb-2 text-grey otherSection">
          1.2 License Restrictions: You may not:
        </p>
        <ul className="list-disc list-inside mb-4 text-grey otherSection">
          <li>
            Modify, reverse engineer, decompile, disassemble, or attempt to
            derive the source code of the Product.
          </li>
          <li>
            Rent, lease, sublicense, or distribute the Product to third parties.
          </li>
          <li>
            Use the Product for any unlawful purpose or in violation of any
            applicable laws or regulations.
          </li>
          <li>
            Circumvent or disable any security or technological features of the
            Product.
          </li>
        </ul>

        <h2 className="text-2xl text-grey otherSection font-semibold mt-6 mb-2">
          2. Ownership & Intellectual Property
        </h2>
        <p className="mb-2 text-grey otherSection">
          2.1 Ownership: Sightshark and all associated intellectual property
          rights, including but not limited to trademarks, copyrights, patents,
          and trade secrets, are owned by Prowiz Analytics. This Agreement does
          not grant you any rights to Sightshark except for the limited license
          provided herein.
        </p>
        <p className="mb-2 text-grey otherSection">
          2.2 Feedback: If you provide feedback, suggestions, or improvements
          regarding the Product, you agree that we may use such feedback without
          any obligation to compensate you.
        </p>

        <h2 className="text-2xl font-semibold text-grey otherSection mt-6 mb-2">
          3. User Obligations & Acceptable Use
        </h2>
        <p className="mb-2 text-grey otherSection">
          3.1 Compliance with Laws: You agree to comply with all applicable laws
          and regulations while using the Product.
        </p>
        <p className="mb-2 text-grey otherSection">
          3.2 Prohibited Conduct: You may not:
        </p>
        <ul className="list-disc list-inside text-grey otherSection mb-4">
          <li>Engage in fraudulent or deceptive activities.</li>
          <li>
            Use the Product in a manner that infringes upon third-party rights.
          </li>
          <li>
            Upload or distribute malicious software, viruses, or harmful code.
          </li>
          <li>
            Attempt to gain unauthorized access to the Product or its associated
            systems.
          </li>
        </ul>

        <h2 className="text-2xl text-grey font-semibold mt-6 mb-2">
          4. Data Collection & Privacy
        </h2>
        <p className="mb-2 text-grey otherSection">
          4.1 Personal Data: Your use of the Product is subject to our Privacy
          Policy, which explains how we collect, use, store, and disclose your
          personal data. By using the Product, you consent to the data practices
          described in the Privacy Policy.
        </p>
        <p className="mb-2 text-grey otherSection">
          4.2 Third-Party Services: The Product may integrate with third-party
          services. Prowiz Analytics is not responsible for the privacy
          practices or terms of these third-party services.
        </p>

        <h2 className="text-2xl text-grey font-semibold mt-6 mb-2">
          5. Subscription & Payment
        </h2>
        <p className="mb-2 text-grey otherSection">
          5.1 Fees: If you purchase a subscription to use the Product, you agree
          to pay all applicable fees as specified at the time of purchase.
        </p>
        <p className="mb-2 text-grey otherSection">
          5.2 Billing & Payment: Payments are processed through third-party
          payment providers, and you must provide accurate billing information.
          Failure to make timely payments may result in suspension or
          termination of your access to the Product.
        </p>
        <p className="mb-2 text-grey otherSection">
          5.3 Refunds: Fees are non-refundable unless otherwise required by law.
        </p>

        <h2 className="text-2xl text-grey font-semibold mt-6 mb-2">
          6. Termination & Suspension
        </h2>
        <p className="mb-2 text-grey otherSection">
          6.1 Termination by You: You may stop using the Product at any time.
        </p>
        <p className="mb-2 text-grey otherSection">
          6.2 Termination by Prowiz Analytics: We may terminate or suspend your
          access to the Product without notice if you violate this Agreement,
          engage in fraudulent activity, or use the Product in a manner that may
          cause harm to us or other users.
        </p>
        <p className="mb-2 text-grey otherSection">
          6.3 Effects of Termination: Upon termination, your license to use the
          Product will immediately end. We may delete your account data unless
          retention is required by law.
        </p>

        <h2 className="text-2xl font-semibold text-grey mt-6 mb-2">
          7. Contact Information
        </h2>
        <div className="">
          <p className="mb-4 text-grey text-xl">
            If you have any questions regarding this Agreement, please contact
            us at:
          </p>
          <p className="mb-1 text-xl text-grey">Prowiz Analytics</p>
          <p className="mb-1 text-xl text-grey">SCO No. 28, Sector 27-C</p>
          <p className="mb-1 text-xl text-grey">Chandigarh - 160019, India</p>
          <p className="mb-1 text-xl text-grey">
            Email:{" "}
            <a href="mailto:info@prowiz.io" className="text-blue-600 underline">
              info@prowiz.io
            </a>
          </p>
          <p className="mb-2 text-grey otherSection">
            For legal inquiries:{" "}
            <a
              href="mailto:privacy@prowiz.io"
              className="text-blue-600 underline"
            >
              privacy@prowiz.io
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UserAgreement;
